import AuthView from "@/views/auth/AuthBase.vue";
export default {
  path: "/auth",
  name: "Authentication",
  component: AuthView,
  redirect: "/auth/time-entry",
  children: [
    {
      path: "time-entry",
      name: "Time Entry",
      component: () => import("@/views/auth/TimeEntry.vue"),
      meta: {
        requiresAuth: true, // Add meta field to indicate protected route
      },
    },
    {
      path: "calendar",
      name: "Calendar",
      component: () => import("@/views/auth/Calendar.vue"),
      meta: {
        requiresAuth: true, // Add meta field to indicate protected route
      },
    },
    {
      path: "clients",
      name: "Clients",
      component: () => import("@/views/auth/Clients.vue"),
      meta: {
        requiresAuth: true, // Add meta field to indicate protected route
      },
    },
    {
      path: "employees",
      name: "Employee",
      component: () => import("@/views/auth/Employee.vue"),
      meta: {
        requiresAuth: true, // Add meta field to indicate protected route
      },
    },
    {
      path: "departments",
      name: "Department",
      component: () => import("@/views/auth/Departments.vue"),
      meta: {
        requiresAuth: true, // Add meta field to indicate protected route
      },
    },
    {
      path: "invoices",
      name: "Invoice",
      component: () => import("@/views/auth/Invoice.vue"),
      meta: {
        requiresAuth: true, // Add meta field to indicate protected route
      },
    },
    {
      path: "phase",
      name: "Phase",
      component: () => import("@/views/auth/Phase.vue"),
      meta: {
        requiresAuth: true, // Add meta field to indicate protected route
      },
    },
    {
      path: "task",
      name: "Task",
      component: () => import("@/views/auth/Task.vue"),
      meta: {
        requiresAuth: true, // Add meta field to indicate protected route
      },
    },
    {
      path: "expense",
      name: "Expenses",
      component: () => import("@/views/auth/Expense.vue"),
      meta: {
        requiresAuth: true, // Add meta field to indicate protected route
      },
    },
    {
      path: "activity",
      name: "Activity",
      component: () => import("@/views/auth/Activity.vue"),
      meta: {
        requiresAuth: true, // Add meta field to indicate protected route
      },
    },
    {
      path: "service-terms",
      name: "Service Terms",
      component: () => import("@/views/auth/ServiceTerms.vue"),
      meta: {
        requiresAuth: true, // Add meta field to indicate protected route
      },
    },
    {
      path: "time-keeper",
      name: "Time Keeper",
      component: () => import("@/views/auth/TimeKeeper.vue"),
      meta: {
        requiresAuth: true, // Add meta field to indicate protected route
      },
    },
    {
      path: "biller",
      name: "Biller",
      component: () => import("@/views/auth/Biller.vue"),
      meta: {
        requiresAuth: true, // Add meta field to indicate protected route
      },
    },
    {
      path: "supervisor",
      name: "Supervisor",
      component: () => import("@/views/auth/Supervisor.vue"),
      meta: {
        requiresAuth: true, // Add meta field to indicate protected route
      },
    },
    {
      path: "loading",
      name: "Loading",
      component: () => import("@/views/auth/LoadingPage.vue"),
      meta: {
        requiresAuth: true, // Add meta field to indicate protected route
      },
    },
    {
      path: "settings",
      name: "Settings",
      component: () => import("@/views/auth/Settings.vue"),
      meta: {
        requiresAuth: true, // Add meta field to indicate protected route
      },
    },
    //    {
    //        path: 'logout',
    //        name: 'Logout',
    //        component: () => import('@/views/auth/Logout.vue')
    //    }
  ],
};
