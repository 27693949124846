import axios from "axios";
import { authHeaders } from "@/store/config";
// import EventBus from "@/common/EventBus";

const state = {
  supervisor: [],
  data: {},
  timekeeperCard: {},
  clientCard: {},
  matterCard: {},
  employeeCard: {},
};

const getters = {};

const actions = {
  getSupervisor(context, data) {
    return new Promise((resolve, reject) => {
      const year = (data && data.year) || "";
      const type = (data && data.type) || "";
      const startDate = (data && data.startDate) || "";
      const endDate = (data && data.endDate) || "";
      const search = (data && data.search) || "";

      axios
        .get("/time-entry/supervisor/dashboard", {
          params: {
            year: year,
            type: type,
            startDate: startDate,
            endDate: endDate,
            search: search,
          },
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response) {
            context.commit("set_supervisor", response.data.metrics);
            // context.commit("set_data", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getSupervisorClient(context, data) {
    return new Promise((resolve, reject) => {
      const year = (data && data.year) || "";
      const id = (data && data.clientId) || "";
      // const startDate = (data && data.startDate) || "";
      // const endDate = (data && data.endDate) || "";

      axios
        .get("/time-entry/supervisor/dashboard/client", {
          params: {
            year: year,
            clientId: id,
          },
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response) {
            context.commit("set_clientcard", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getSupervisorMatter(context, data) {
    return new Promise((resolve, reject) => {
      const year = (data && data.year) || "";
      const id = (data && data.clientId) || "";
      // const startDate = (data && data.startDate) || "";
      // const endDate = (data && data.endDate) || "";

      axios
        .get("/time-entry/supervisor/dashboard/matter", {
          params: {
            year: year,
            matterId: id,
          },
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response) {
            context.commit("set_projectcard", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getSupervisorMatterEmployee(context, data) {
    return new Promise((resolve, reject) => {
      const id = (data && data.matterId) || "";
      const employeeId = (data && data.employeeId) || "";

      axios
        .get("/time-entry/supervisor/dashboard/matter/employee", {
          params: {
            matterId: id,
            employeeId: employeeId,
          },
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response) {
            context.commit("set_employeecard", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getMatterCard(context, data) {
    return new Promise((resolve, reject) => {
      const year = (data && data.year) || "";
      const month = (data && data.month) || "";
      const id = (data && data.matterId) || "";

      axios
        .get("/time-entry/timekeeper/dashboard/project-metrics", {
          params: {
            month: month,
            matterId: id,
            year: year,
          },
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response) {
            context.commit("set_projectcard", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  set_supervisor(state, data) {
    state.supervisor = data;
  },
  set_data(state, data) {
    state.data = data;
  },
  set_timekeepercard(state, data) {
    state.timekeeperCard = data;
  },
  set_clientcard(state, data) {
    state.clientCard = data;
  },
  set_projectcard(state, data) {
    state.matterCard = data;
  },
  set_employeecard(state, data) {
    state.employeeCard = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
