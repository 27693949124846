// import router from '@/router';
import axios from "axios";
import { authHeaders } from "@/store/config";
import EventBus from "@/common/EventBus";

const state = {
  entries: [],
  entriesSearchRange: {},
  runningTimer: null,
  aggregates: {},
};

const getters = {};

const actions = {
  getTimeEntry(context, data) {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/time-entry/all/paginated?date=${data.date}&order=${data.order}`,
          {
            headers: {
              ...authHeaders(localStorage.getItem("access_token")),
            },
          }
        )
        .then((response) => {
          context.commit("set_running_entry", response.data.runningTimer);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getTimeEntryBySearch(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/time-entry/all/paginated`, {
          params: {
            date: data.date,
            order: data.order,
            search: data.search,
            startDate: data.startDate,
            endDate: data.endDate,
            pageNo: data.pageNo,
            pageSize: data.pageSize,
          },
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response) {
            context.commit("set_entriesSearchRange", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getTimeEntryByRange(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/time-entry/calendar`, {
          params: {
            order: data.order,
            startDate: data.startDate,
            endDate: data.endDate,
            date: data.date,
            pageNo: data.pageNo,
            pageSize: data.pageSize,
          },
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response) {
            context.commit("set_entriesSearchRange", response.data.data);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getEntryCalendar(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/time-entry/calendar?order=${data.order}&startDate=${data.startDate}&endDate=${data.endDate}&date=${data.date}`,
          {
            headers: {
              ...authHeaders(localStorage.getItem("access_token")),
            },
          }
        )
        .then((response) => {
          context.commit("set_entries", response.data.data);
          context.commit("set_aggregates", response.data.aggregates);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteTimeEntry(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/time-entry/${data}`, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response.data) {
            EventBus.dispatch("show-snackbar", {
              color: "success",
              message: response.data.message,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createTimeEntry(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/time-entry/", data, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response.data) {
            EventBus.dispatch("show-snackbar", {
              color: "success",
              message: response.data.message,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  startTimer(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/time-entry/start-timer", data, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response.data) {
            EventBus.dispatch("show-snackbar", {
              color: "success",
              message: response.data.message,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  cancelTimer(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/time-entry/${data.id}`, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response.data) {
            EventBus.dispatch("show-snackbar", {
              color: "success",
              message: response.data.message,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateTimer(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/time-entry/${data.id}`, data.timer, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response.data) {
            EventBus.dispatch("show-snackbar", {
              color: "success",
              message: response.data.message,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  stopTimer(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/time-entry/end-timer/${data.timeEntry}`, data, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          if (response.data) {
            EventBus.dispatch("show-snackbar", {
              color: "success",
              message: response.data.message,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  pauseTimer(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `/time-entry/pause-timer/${data.timeEntry}`,
          {
            pause_timer: data.pause_timer,
            timer_pause_time: data.timer_pause_time,
          },
          {
            headers: {
              ...authHeaders(localStorage.getItem("access_token")),
            },
          }
        )
        .then((response) => {
          if (response.data) {
            EventBus.dispatch("show-snackbar", {
              color: "success",
              message: response.data.message,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  playTimer(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `/time-entry/play-timer/${data.timeEntry}`,
          {
            continue_timer: data.continue_timer,
          },
          {
            headers: {
              ...authHeaders(localStorage.getItem("access_token")),
            },
          }
        )
        .then((response) => {
          if (response.data) {
            EventBus.dispatch("show-snackbar", {
              color: "success",
              message: response.data.message,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateSetupWizard(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`org/setup-wizard-status/${data.id}`, {
          headers: {
            ...authHeaders(localStorage.getItem("access_token")),
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  set_running_entry(state, data) {
    state.runningTimer = data;
  },
  set_entries(state, data) {
    state.entries = data;
  },
  set_aggregates(state, data) {
    state.aggregates = data;
  },
  set_entriesSearchRange(state, data) {
    state.entriesSearchRange = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
