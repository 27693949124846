<template>
  <div class="mt-3 mx-3">
    <v-app-bar class="nav_bar" color="#fff" dense outlined light elevation="0">
      <span class="page_title">{{ currentTitle }}</span>
      <v-spacer></v-spacer>
    </v-app-bar>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "LoginPage",
  components: {},
  data: () => ({
    show: false,
    commentDrawer: false,
    comments: 2,
    email: "",
    password: "",
    items: [
      { title: "Home", icon: "mdi-home-city" },
      { title: "My Account", icon: "mdi-account" },
      { title: "Users", icon: "mdi-account-group-outline" },
    ],
  }),
  computed: {
    currentTitle() {
      const isLawRelated = this.organisation?.is_law_related;

      switch (this.$route.name) {
        case "Clients":
          return isLawRelated ? "Clients & Matters" : "Clients & Projects";
        case "Employee":
          return isLawRelated ? "Time Keepers & Ranks" : "Employees & Ranks";
        case "Department":
          return "Departments, Offices & Profit Centers";
        default:
          return this.$route.name;
      }
    },

    ...mapState({
      organisation: (state) => state.Auth.user.org,
    }),
    dynamicLinks() {
      const isLawRelated = this.organisation?.is_law_related;

      return this.links.map((link) => {
        if (link.title) {
          return {
            ...link,
            title: isLawRelated
              ? link.title
                  .replace(/Client Project/gi, "Client Matter")
                  .replace(/Employee and Ranks/gi, "Time Keeper and Ranks")
              : link.title,
          };
        }
        return link;
      });
    },
  },
};
</script>

<style scoped>
.logo_banner {
  width: 11rem;
}
.page_title {
  font-size: 18px;
  font-weight: 600;
  color: black;
}
.some-style >>> .v-input__slot::before {
  border-style: none !important;
}
.comment_title {
  font-size: 14px;
  font-weight: 400;
}
.comment_date {
  font-size: 13px;
  color: #666666;
}
.comment_text {
  font-size: 14px;
  font-weight: 400;
}
.nav_text {
  font-size: 12px;
}
</style>
